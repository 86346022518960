import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../Context/AuthContext';
import '../styles/Login.css';
import splashlogo from '../Assets/logo.png';

const Login = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { passwordSuccess, handleSubmit, jwt } = useContext(AuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(jwt) {
      const intendedPath = sessionStorage.getItem("intendedPath");
      if (intendedPath) {
        sessionStorage.removeItem("intendedPath");
        navigate(intendedPath);
      } else {
        navigate('/');
      }
    }
  },[jwt, navigate])

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    try {
      await handleSubmit(event);
    } finally {
      setIsSubmitting(false);
    }
  };

  const inputClassNames = ['input-group'];

  if (passwordSuccess === false) {
    inputClassNames.push('error');
  }

  return (
    <div className="page-container">
      <div className="bg"></div>
      <div className="login-container">
        {/* <h1 className="login-title">Login Page</h1> */}
        <div className='logo'><img className="login-logo" src={splashlogo} alt="login logo" /></div>
        <form onSubmit={handleFormSubmit} className="form">
        
          {/* <div className={inputClassNames.join(' ')}>
            <label htmlFor="email">email</label>
            <input type="email" name="email" id="email" />
            <span className="msg">Incorrect email</span>
          </div> */}

          <div className={inputClassNames.join(' ')}>
            <label htmlFor="password">Password</label>
            <input type="password" name="password" id="password" />
            <span className="msg">Incorrect password</span>
          </div>

          <button type="submit" className="login-button" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Login'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
