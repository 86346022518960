import React, {useEffect} from 'react'
import "../styles/MainMenu.css"
import { Link } from 'react-router-dom'

export default function MainMenu({setHeading, setSubheading}) {

  useEffect(() => {
    setHeading("")
    setSubheading("")
  }, [setHeading, setSubheading])

  return (
    <div className='main-menu'>
      <div className="menu">
        <Link to="/nCino">
          <div className="menu-item">
            <div className="subtitle"><h1>nCino</h1></div>
            <div className="menu-bar"></div>
          </div>
        </Link>
        <Link to="/Encompass-SMM">
          <div className="menu-item">
            <div className="title"><h1>Encompass</h1></div>
            <div className="subtitle"><h1>SMM</h1></div>
            <div className="menu-bar"></div>
          </div>
        </Link>
        <Link to="/Encompass-Portfolio">
          <div className="menu-item">
            <div className="title"><h1>Encompass</h1></div>
            <div className="subtitle"><h1>Portfolio</h1></div>
            <div className="menu-bar"></div>
          </div>
        </Link>
      </div>
    </div>
  )
}

