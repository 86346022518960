import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import fileDict from "../fileList.json";
import searchPdfFiles from "./Search";
import { Spinner } from "@react-pdf-viewer/core";
import AuthContext from "../Context/AuthContext";

import "../styles/GlobalFileList.css";

export default function GlobalFileList() {
  const [searchTerm, setSearchTerm] = useState("");
  const [visibleFiles, setVisibleFiles] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const { jwt } = useContext(AuthContext);

  const SEARCH_LENGTH = 2;

  const globalFilePaths = [];

  for (const [product, categories] of Object.entries(fileDict)) {
    for (const [category, pdfs] of Object.entries(categories)) {
      for (const pdf of pdfs) {
        globalFilePaths.push(`${window.location.origin}/pdfs/${product}/${category}/${pdf}`);
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value.length < SEARCH_LENGTH) {
      setLoading(false);
    }
  };

  const SEARCH_DELAY_MS = 150; // set the delay time in milliseconds

  useEffect(() => {
    let searchTimeoutId = null;
    if (searchTerm.length >= SEARCH_LENGTH) {
      setLoading(true);
      // set a timeout to delay the execution of the search function
      searchTimeoutId = setTimeout(() => {
        const options = { headers: { Authorization: `Bearer ${jwt}` } };
        searchPdfFiles(globalFilePaths, searchTerm.toLowerCase(), options).then((result) => {
          setSearchResults(result);
          setLoading(false);
        });
      }, SEARCH_DELAY_MS);
    } else {
      setSearchResults([]);
    }

    // clear the timeout on cleanup
    return () => {
      if (searchTimeoutId) {
        clearTimeout(searchTimeoutId);
      }
    };
  }, [searchTerm]);

  // const alphabet = 'abcdefghijklmnopqrstuvwxyz';

  function FileList() {
    let filteredFiles = [];

    if (searchTerm.length >= SEARCH_LENGTH) {
      filteredFiles = searchResults.map((result) =>
        result.filename
          .replace(`${window.location.origin}/pdfs/`, "")
          .replace(".pdf", "")
      );
      if (filteredFiles.length === 0) {
        return (
          <div className="no-result">
            <h2>None</h2>
          </div>
        );
      }
    } else {
      filteredFiles = [];
    }

    const filesByLetter = filteredFiles.reduce((acc, fileName) => {
      const letter = fileName.split("/").pop().charAt(0).toLowerCase();
      acc[letter] = [...(acc[letter] || []), fileName];
      return acc;
    }, {});

    const toggleVisibility = (letter) => {
      setVisibleFiles({
        ...visibleFiles,
        [letter]: !visibleFiles[letter],
      });
    };
    const alphabet = Object.keys(filesByLetter).sort();

    const fileHeaders = alphabet.map((letter) => (
      <div key={letter} className="letter-header">
        <div className="header-row" onClick={() => toggleVisibility(letter)}>
          <div className="letter">
            <h1>{letter.toUpperCase()}</h1>
          </div>
          {filesByLetter[letter] ? (
            visibleFiles[letter] ? (
                <h2 className="arrow">&#8963;</h2>
            ) : (
                <h2 className="arrow">&#8964;</h2>
            )
          ) : null}
        </div>
        {!visibleFiles[letter] && (
          <div className="letter-files">
            {(filesByLetter[letter] || []).map((fileName) => {
              const result = searchResults.find((res) =>
                res.filename.includes(fileName)
              );
              const pageNum = result ? result.page : null;
              const fileMatch = pageNum === 0;
              return (
                <Link to={`/${fileName}`}>
                  <div key={fileName} className="letter-file">
                    <p>{fileName.split("/").slice(-1)[0].replace(".pdf", "")}</p>
                    <p className="file-location">
                      /{fileName.split("/").slice(0, -1).join("/")}/
                    </p>
                    {pageNum !== null && (
                      <p className="page-number">
                        {fileMatch ? "File Name" : `Page ${pageNum}`}
                      </p>
                    )}
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    ));

    return <div className="global-pdf-list">{fileHeaders}</div>;
  }

  return (
    <div className="global-file-list">
      <div className="global-search-bar">
        <input
          type="text"
          placeholder="Search All Files"
          onChange={handleSearchTermChange}
        />
      </div>
      {loading ? <Spinner /> : <FileList />}
    </div>
  );
}
