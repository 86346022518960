import React from 'react'
import "../styles/404.css"

const NotFound = () => {
  return (
    <div>
      <div className="not-found"><h1>404 - Not Found</h1></div>
    </div>
  )
}

export default NotFound
