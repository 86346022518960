import { Outlet, Navigate, useLocation } from "react-router-dom";
import React, { useContext } from "react";
import AuthContext from "../Context/AuthContext";

const PrivateRoutes = () => {
  const { jwt } = useContext(AuthContext);
  const location = useLocation();
  const intendedPath = sessionStorage.getItem("intendedPath");

  if (!jwt) {
    sessionStorage.setItem("intendedPath", location.pathname);
    return <Navigate to="/login" />;
  }

  if (intendedPath) {
    sessionStorage.removeItem("intendedPath");
    return <Navigate to={intendedPath} replace />;
  }

  return <Outlet />;
};

export default PrivateRoutes;
