import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { openDB } from "idb";

const DB_NAME = "template-jobaid-db";
const STORE_NAME = "auth";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [jwt, setJwt] = useState(null);
  const [loading, setLoading] = useState(true);
  const [passwordSuccess, setPasswordSuccess] = useState(null);

  useEffect(() => {
    async function getJwtFromDatabase() {
      const db = await openDB(DB_NAME, 1, {
        upgrade(db) {
          db.createObjectStore(STORE_NAME);
        },
      });

      const jwtFromDB = await db.get(STORE_NAME, "jwt");
      
      // DEV
      // if (jwtFromDB) {
      //   setJwt(jwtFromDB);
      // } else {
      //   setJwt(null);
      // }

      // PROD

      if (jwtFromDB) {
        try {
          const response = await fetch(
            "https://jobaidtemplate.brandongorman.me/api/login",
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${jwtFromDB}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 200) {
            console.log("JWT Verified");
            // Log the body of the response
            const responseBody = await response.json();
            console.log(responseBody);
            setJwt(jwtFromDB);
          } else {
            console.log("Invalid JWT");
            setJwt(null);
          }
        } catch (error) {
          console.log(error);
          setJwt(null);
        }
      } else {
        setJwt(null);
      }

      setLoading(false);
    }

    getJwtFromDatabase();
  }, [jwt]);

  let navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    // DEV Request
    // let response = await fetch("http://192.168.2.30:8000/api/token/", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     email: "admin@admin.com",
    //     password: event.target.password.value,
    //   }),
    // });

    // PROD Request
    const password = event.target.password.value;
    const response = await fetch("https://jobaidtemplate.brandongorman.me/api/login", {
      method: "POST",
      body: JSON.stringify({ password }),
      headers: {
        "Content-Type": "application/json",
      },
    });    

    // let data = await response.json(); // Dev

    let jwtTmp = null;
    if (response.ok) {
      jwtTmp = response.headers.get("authorization").replace("Bearer ", ""); // Prod
      // jwtTmp = data.access; // Dev
      // console.log(`Response JWT - ${jwt}`);
      const db = await openDB(DB_NAME, 1, {
        upgrade(db) {
          db.createObjectStore(STORE_NAME);
        },
      });
      await db.put(STORE_NAME, jwtTmp, "jwt");
      const intendedPath = sessionStorage.getItem("intendedPath");
      if (intendedPath) {
        sessionStorage.removeItem("intendedPath");
        navigate(intendedPath);
      } else {
        navigate("/");
      }
    } else {
      setPasswordSuccess(false);
    }
    setJwt(jwtTmp);
    // console.log(response);
  };

  // console.log(`JWT: ${jwt}`);
  let contextData = {
    handleSubmit: handleSubmit,
    jwt: jwt,
    passwordSuccess: passwordSuccess
  };

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? <div>Loading...</div> : children}
    </AuthContext.Provider>
  );
};
