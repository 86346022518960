import Header from "./Header";
import AuthContext from "../Context/AuthContext";

import React, { useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Viewer, Worker, SpecialZoomLevel, ProgressBar } from "@react-pdf-viewer/core";
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import "../styles/PdfDocument.css";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

const PDFDocument = ({setHeading, setSubheading, menu, fileType}) => {
  const { jwt } = useContext(AuthContext);
  const navigate = useNavigate();
  console.log(jwt)
  if (!jwt) {
    navigate("/login");
  }

  useEffect(() => {
    const header = document.querySelector(".header");
    const headerTriangle = document.querySelector(".header-triangle");
    const headerContainer = document.querySelector(".header-container");
    const headerContent = document.querySelector(".header-content");

    header.classList.add("horizontal-header");
    headerTriangle.classList.add("horizontal-header-triangle");
    headerContainer.classList.add("horizontal-header-container");
    headerContent.classList.add("horizontal-header-content");

    return () => {
      header.classList.remove("horizontal-header");
      headerTriangle.classList.remove("horizontal-header-triangle");
      headerContainer.classList.remove("horizontal-header-container");
      headerContent.classList.remove("horizontal-header-content");
    };
  }, []);

  const {filename} = useParams()
  const subheading = filename;

  useEffect(() => {
    setHeading(menu.replace('-', ' '))
    setSubheading(subheading)
  }, [subheading, menu, setHeading, setSubheading])

  const filePath =
    process.env.PUBLIC_URL + `/pdfs/${menu}/${fileType}/${filename}.pdf`;


  // const transform = (slot) => {
  //   const { CurrentPageInput, NumberOfPages } = slot;
  
  //   return {
  //     ...slot,
  //     CurrentPageInput: () => (
  //       <>
  //         <CurrentPageInput />/<NumberOfPages />
  //       </>
  //     ),
  //     NumberOfPages: () => <></>,
  //     Open: () => <></>,
  //     EnterFullScreen: () => <></>,
  //     SwitchTheme: () => <></>,
  //   };
  // };
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
  });
  // const toolbarPluginInstance = toolbarPlugin();
  // const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  return (
    <div>
      {/* <Header heading={heading} subheading={subheading} /> */}
      <div className="pdf-page">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div className="pdf-document">
            {/* <div className="pdf-toolbar">
              <Toolbar>
                {renderDefaultToolbar(transform)}
              </Toolbar>
            </div> */}
            <Viewer
              fileUrl={filePath}
              httpHeaders={{ Authorization: `Bearer ${jwt}` }}
              withCredentials={true}
              plugins={[defaultLayoutPluginInstance]}
              defaultScale={SpecialZoomLevel.PageFit}
              theme='dark'
              // renderLoader={(percentages) => (
              //   <div style={{ width: '240px' }}>
              //       <ProgressBar progress={Math.round(percentages)} />
              //   </div>
              // )}
            />
          </div>
          </Worker>
      </div>
    </div>
  );
};

export default PDFDocument;
