import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FileList from './Components/FileList';
import PdfDocument from './Components/PdfDocument';
import MainMenu from './Components/MainMenu';
import SubMenu from './Components/SubMenu';
import Splash from './Components/Splash';
import Login from './Components/Login';
import NotFound from './Components/404'
import PrivateRoutes from './Utils/PrivateRoutes';
import { AuthProvider } from './Context/AuthContext';
import Header from './Components/Header';
import {useState } from 'react';

function App() {
  const [heading, setHeading] = useState('');
  const [subheading, setSubheading] = useState('');

  return (
    <div className="app-container">
    <Splash/>
    <Router>
      <AuthProvider>
      <Header heading={heading} subheading={subheading}/>
      <Routes>
        <Route element={<PrivateRoutes />} exact>
          <Route path="/" element={<MainMenu setHeading={setHeading} setSubheading={setSubheading}/>}/>

          <Route path="/nCino" element={<SubMenu menu="nCino" setHeading={setHeading} setSubheading={setSubheading}/>} exact/>
          <Route path="/Encompass-SMM" element={<SubMenu menu="Encompass-SMM" setHeading={setHeading} setSubheading={setSubheading}/>} exact/>
          <Route path="/Encompass-Portfolio" element={<SubMenu menu="Encompass-Portfolio" setHeading={setHeading} setSubheading={setSubheading}/>}exact />

          <Route path="/nCino/Functional" element={<FileList menu="nCino" fileType="Functional" setHeading={setHeading} setSubheading={setSubheading}/>} exact />
          <Route path="/Encompass-SMM/Functional" element={<FileList menu="Encompass-SMM" fileType="Functional" setHeading={setHeading} setSubheading={setSubheading}/>} exact />
          <Route path="/Encompass-Portfolio/Functional" element={<FileList menu="Encompass-Portfolio" fileType="Functional" setHeading={setHeading} setSubheading={setSubheading}/>} exact />

          <Route path="/nCino/Personas" element={<FileList menu="nCino" fileType="Personas" setHeading={setHeading} setSubheading={setSubheading}/>} exact />
          <Route path="/Encompass-SMM/Personas" element={<FileList menu="Encompass-SMM" fileType="Personas" setHeading={setHeading} setSubheading={setSubheading}/>} exact />
          <Route path="/Encompass-Portfolio/Personas" element={<FileList menu="Encompass-Portfolio" fileType="Personas" setHeading={setHeading} setSubheading={setSubheading}/>} exact />


          <Route path="/nCino/Functional/:filename" element={<PdfDocument menu="nCino" fileType="Functional" setHeading={setHeading} setSubheading={setSubheading}/>} />
          <Route path="/nCino/Personas/:filename" element={<PdfDocument menu="nCino" fileType="Personas" setHeading={setHeading} setSubheading={setSubheading} />} />
          <Route path="/Encompass-SMM/Functional/:filename" element={<PdfDocument menu="Encompass-SMM" fileType="Functional" setHeading={setHeading} setSubheading={setSubheading}/>} />
          <Route path="/Encompass-SMM/Personas/:filename" element={<PdfDocument menu="Encompass-SMM" fileType="Personas" setHeading={setHeading} setSubheading={setSubheading}/>} />
          <Route path="/Encompass-Portfolio/Functional/:filename" element={<PdfDocument menu="Encompass-Portfolio" fileType="Functional" setHeading={setHeading} setSubheading={setSubheading}/>} />
          <Route path="/Encompass-Portfolio/Personas/:filename" element={<PdfDocument menu="Encompass-Portfolio" fileType="Personas" setHeading={setHeading} setSubheading={setSubheading}/>} />
        </Route>
        <Route path="/login" element={<Login/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
      </AuthProvider>
    </Router>
    </div>
  );
}

export default App;
