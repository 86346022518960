import React, {useEffect}  from 'react'
import "../styles/SubMenu.css"
import { Link } from 'react-router-dom'

export default function SubMenu({menu, setHeading, setSubheading}) {

  useEffect(() => {
    setHeading(menu.replace('-', ' '))
    setSubheading('')
  },  [setHeading, setSubheading ,menu])

  return (
    <div className='sub-menu'>
      <div className="menu">
        <Link to={`/${menu}/Functional`} className="top">
          <div className="menu-item">
            <div className="title"><h1 className='top'>Functional</h1><h1 className='bottom'>Job Aids</h1></div>
            <div className="menu-bar"></div>
            <div className="subtitle"><h4>A small description of this context. Just one or two sentences maximum.</h4></div>
          </div>
        </Link>
        <Link to={`/${menu}/Personas`} className="bottom">
          <div className="menu-item">
            <div className="title"><h1 className='top'>Personas</h1><h1 className='bottom'>Job Aids</h1></div>
            <div className="menu-bar"></div>
            <div className="subtitle"><h4>A small description of this context. Just one or two sentences maximum.</h4></div>
          </div>
        </Link>
        </div>
    </div>
  )
}

