import React, {useState, useEffect} from 'react'
import "../styles/Splash.css"
import splashlogo from "../Assets/splash-logo.png"

const Splash = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.getElementById("theme-color").setAttribute("content", "#002e54ff");
        const timeout = setTimeout(() => {
        setLoading(false);
        document.getElementById("theme-color").setAttribute("content", "#262f3aff");
      }, 1500);
      
      return () => clearTimeout(timeout);
    }, []);

    return (
        <div className={loading ? "splash" : "hidden"}>
            <div className='border'>
                <div className="splash-logo-bg"></div>
                <img className="splash-logo" src={splashlogo} alt="splash logo" />
            </div>
            <div className="tagline">
                <h2>Resources Wherever You Are</h2>
            </div>
        </div>
        
    )
}

export default Splash
