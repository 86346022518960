import React, { useRef, useContext } from "react";
import sideMenuLogo from "../Assets/splash-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/Header.css";
import AuthContext from "../Context/AuthContext"
import GlobalFileList from "./GlobalFileList";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faSearch } from '@fortawesome/free-solid-svg-icons'

export default function Header(props) {
  const history = useNavigate();
  const location = useLocation();
  const sideMenuRef = useRef(null);
  const sideMenuDimRef = useRef(null);
  const { jwt } = useContext(AuthContext);

  const showBackButton = location.pathname !== "/";

  const handleBackButtonClick = () => {
    history(-1);
  };

  const handleMenuButtonClick = (event) => {
    event.stopPropagation(); // prevent the click event from bubbling up
  
    sideMenuRef.current.classList.add("open");
    sideMenuDimRef.current.classList.add("open");
    document.addEventListener("click", handleClickOutsideMenu);
  };

  const handleClickOutsideMenu = (event) => {
    if (sideMenuRef.current) {
      if (sideMenuRef.current.classList.contains("open") && !sideMenuRef.current.contains(event.target)) {
        const isLetterHeader = event.target.classList.contains("letter");
        const isArrowHeader = event.target.classList.contains("arrow");
        if (!isLetterHeader && !isArrowHeader) {
          sideMenuRef.current.classList.remove("open");
          sideMenuDimRef.current.classList.remove("open");
          document.removeEventListener("click", handleClickOutsideMenu);
        }
      }
    }
  };


  if (!jwt) {
    return (<></>)
  } else {
  return (
    <div className="header-container">
      <div className="header">
        <div className="header-content">
          {showBackButton && (
            <div className="back" clickable onClick={handleBackButtonClick}>
              <h1>&lt;</h1>
            </div>
          )}
          <h1>{props.heading}</h1>
          <p>{props.subheading}</p>
          <div className="logo-menu-button" clickable onClick={handleMenuButtonClick}>
          <FontAwesomeIcon icon={faSearch} size="lg" className="header-icon"/>
          </div>
        </div>
      </div>
      <div className="header-triangle"></div>
      <div className="side-menu" ref={sideMenuRef}>
        <div className="side-menu-header">
          <img src={sideMenuLogo} alt="side-menu-logo" className="side-menu-logo-img" />
        </div>
        <GlobalFileList />
      </div>
      <div className="side-menu-overlay" ref={sideMenuDimRef}></div>
    </div>
  );
}}
