// var pdfjsLib = window['pdfjs-dist/build/pdf'];
import * as pdfjsLib from 'pdfjs-dist/build/pdf';

pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

async function searchPdfFiles(files, term, options) {
  const result = [];
  const { headers = {} } = options;

  for (let i = 0; i < files.length; i++) { // change <= to <

    const file = files[i];
    const loadingTask = pdfjsLib.getDocument({
      url: file,
      httpHeaders: headers,
    });

    try {
      const pdf = await loadingTask.promise;
      const numPages = pdf.numPages;
      let foundInFile = false; // add this variable

      for (let pageNum = 1; pageNum <= numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const textContent = await page.getTextContent();
        const textItems = textContent.items;

        for (let j = 0; j < textItems.length; j++) {
          const item = textItems[j];

          if (item.str.toLowerCase().includes(term.toLowerCase())) {
            result.push({ filename: file, page: pageNum });
            foundInFile = true;
            break;
          }
        }

        if (foundInFile) { // check if the term was found in the current file
          break;
        }
      }

      const filename = file.split('/').pop().replace('.pdf', '');
      if (!foundInFile && filename.toLowerCase().includes(term.toLowerCase())) {
        result.push({ filename: file, page: 0 });
        foundInFile = true;
        break;
      }
    } catch (error) {
      console.error(`Error loading ${file}: ${error.message}`);
    }
  }

  return result;
}

export default searchPdfFiles